<template>
  <v-container class="mb-16" fluid>
    <v-row>
      <v-col md="7" cols="12">
        <resource-filter
          @filtersChanged="filtersChanged"
          @filtersRestored="filtersRestored"
        />
        <v-data-table
          v-model="selected"
          :footer-props="footerProps"
          :headers="headers"
          :items="items"
          :loading="isLoadingTable"
          :loading-text="$trans('loading')"
          :no-data-text="$trans('nothing_found_here')"
          :no-results-text="$trans('nothing_found_here')"
          :options.sync="tableOptions"
          :server-items-length="total"
          :show-select="!noData"
          class="calendesk-datatable"
          mobile-breakpoint="960"
        >
          <template #[`header.data-table-select`]>
            <div class="text-center">
              <v-simple-checkbox
                v-model="selectAllState"
                :ripple="false"
                :indeterminate="isIndeterminateForSelectAll"
                color="primary"
                @input="selectAllItemsOnChange"
              />
            </div>
          </template>

          <template #[`item`]="{ item }">
            <resource-row
              :item="item"
              :is-selected="
                isRowSelected({
                  id: item.id,
                })
              "
              @selectOnChange="itemRowEventOnSelectChange"
            />
          </template>
        </v-data-table>
        <table-footer-menu
          :on-select-actions="onSelectActions"
          :selected="selected"
          :selection-map="
            (rowItem) => {
              return rowItem.item;
            }
          "
        />
      </v-col>
      <v-col md="5" cols="12">
        <calendesk-information-message>
          {{ $trans("resource_info") }}
          <br />
          <br />
          {{ $trans("resource_info_2") }}
        </calendesk-information-message>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedList from "@/calendesk/mixins/sharedList";
import { mapActions, mapGetters } from "vuex";
import TableFooterMenu from "@/components/TableFooterMenu.vue";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import tagsGroupsResourcesActions from "@/calendesk/mixins/tagsGroupsResourcesActions";
import ResourceFilter from "@/views/dashboard/pages/Settings/components/ResourceFilter.vue";
import ResourceRow from "@/views/dashboard/pages/Settings/components/ResourceRow.vue";

export default {
  name: "ResourceList",
  components: {
    ResourceRow,
    ResourceFilter,
    CalendeskInformationMessage,
    TableFooterMenu,
  },
  mixins: [tagsGroupsResourcesActions, sharedList],
  data() {
    return {
      cacheId: "resource-list",
      requestActionName: "fetchResourceList",
      headers: [
        {
          text: this.$trans("id"),
          align: "start",
          value: "id",
          class: "header-style",
          sortable: true,
        },
        {
          text: this.$trans("resource"),
          value: "resource",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("quantity"),
          value: "quantity",
          align: "center",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("services_with_resource"),
          value: "number_of_services",
          align: "center",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("manage"),
          value: "menu",
          class: "header-style",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      shouldRefreshResources: "resource/shouldRefreshResources",
    }),
    onSelectActions() {
      let actions = [];

      if (this.loggedUserCanEditResources) {
        actions.push({
          title: { single: "delete", many: "delete" },
          action: this.handleRemoveResources,
          target: "resource",
          icon: "$trash-default",
          color: "error",
          class: "error--text",
        });
      }

      return actions;
    },
  },
  watch: {
    shouldRefreshResources(status) {
      if (status) {
        this.forceReload();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchResourceList: "resource/fetchPaginated",
    }),
  },
};
</script>
